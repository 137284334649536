import * as React from "react";

import SvgDelete from "../../../assets/svg/Delete";
import SvgDuplicate from "../../../assets/svg/Duplicate";
import { SiteContext } from "../../../contexts";

import "./style.css";

/**
 * `<Wrapper>`
 */
const Wrapper = (props: WrapperProps) => {
	const componentRef = React.useRef<HTMLSpanElement>(null);
	const [wrapperHeight, setWrapperHeight] = React.useState(0);
	const { selectEditorID, selectHoverEditorID, moduleActions } =
		React.useContext(SiteContext);

	const {
		selectedEditorID,
		editorID,
		component,
		children,
		isSelectionAllowed,
		type,
	} = props;

	const isNavigationModule = !!type && ["header", "footer"].includes(type);

	React.useEffect(() => {
		if (!!componentRef && componentRef.current) {
			setWrapperHeight(componentRef.current.scrollHeight);
		}
	}, [children]);

	React.useEffect(() => {
		isSelected && scrollToComponent();
	}, [componentRef]);

	const cb = React.useCallback(
		(entries: IntersectionObserverEntry[]) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting && selectHoverEditorID && editorID) {
					selectHoverEditorID(editorID);
				}
			});
		},
		[selectHoverEditorID, editorID],
	);

	const ioConfiguration = {
		rootMargin: "-30% 0% -70% 0%",
		threshold: 0,
	};

	const observer = new IntersectionObserver(cb, ioConfiguration);

	React.useEffect(() => {
		if (componentRef.current) {
			observer.observe(componentRef.current);
		}
		return () => {
			if (componentRef.current) {
				observer.unobserve(componentRef.current);
			}
		};
	}, [componentRef, observer]);

	const handleClick = (e: React.SyntheticEvent) => {
		if (
			(!editorID && editorID !== 0) ||
			typeof window === `undefined` ||
			!isSelectionAllowed
		) {
			return;
		}
		selectEditorID && selectEditorID(props, undefined, e);
	};

	const scrollToComponent = () => {
		if (!componentRef.current) return;
		const pos = componentRef.current.style.position;
		const top = componentRef.current.style.top;
		componentRef.current.style.position = "relative";
		componentRef.current.style.top = "-34px";
		componentRef.current.scrollIntoView({
			behavior: "smooth",
			block: "nearest",
		});
		componentRef.current.style.top = top;
		componentRef.current.style.position = pos;

		const offsetTop = componentRef.current.offsetTop;
		if (offsetTop === 0) {
			componentRef.current.style.marginTop = "36px";
		}
	};

	const isSelected = !!selectedEditorID && selectedEditorID === editorID;
	isSelected && scrollToComponent();

	const removeItem = (e: React.SyntheticEvent) => {
		e.stopPropagation();
		moduleActions && editorID && moduleActions.deleteModuleAction(editorID);
	};

	const duplicateItem = (e: React.SyntheticEvent) => {
		e.stopPropagation();
		moduleActions && editorID && moduleActions.duplicateModuleAction(editorID);
	};

	return (
		<>
			{component === "Header" && (
				<style
					dangerouslySetInnerHTML={{
						__html: `
          [data-text="Header"].--selected::before {
            height: ${wrapperHeight - 8}px;
          }`,
					}}
				/>
			)}
			{isNavigationModule && (
				<span
					className={`${isSelected ? "--selected-header-footer" : ""}`}
					data-text={component}
					ref={componentRef}
					onClick={handleClick}
				>
					{children}
				</span>
			)}
			{!isNavigationModule && (
				<span
					className={`${isSelected ? "--selected" : ""}`}
					data-text={component}
					ref={componentRef}
					onClick={handleClick}
				>
					<span
						className={`${
							isSelected ? "--selected-nameplate" : "--not-selected-nameplate"
						}`}
					>
						{component}
						<span className="span-svg-action" onClick={duplicateItem}>
							<SvgDuplicate className="svg-action" />
						</span>
						<span className="span-svg-action" onClick={removeItem}>
							<SvgDelete className="svg-action" />
						</span>
					</span>
					{children}
				</span>
			)}
		</>
	);
};

interface WrapperProps {
	selectedEditorID: number | null;
	editorID?: number;
	component: string;
	type?: string;
	parentEditorID?: number;
	isSelectionAllowed: boolean;
	children: JSX.Element;
}

export { Wrapper as ComponentWrapper };

import * as React from "react";

import { GriddoLinkProps } from "../../components/GriddoLink";
import { Site } from "../../types/core";

// Componente que hace de placeholder para el Link de Gatsby
function Link(props: GriddoLinkProps) {
	const { children, ...rest } = props;

	return (
		<a data-griddo="link" {...rest}>
			{children}
		</a>
	);
}

// Función que hace de place holder para la función navigate de Gatsby.
const navigate = () => null;

const defaultValue: SiteContextProps = {
	linkComponent: Link,
	navigate: navigate,
};

const SiteContext = React.createContext<SiteContextProps>(defaultValue);

/**
 * `<SiteProvider>`
 */
function SiteProvider(props: SiteProviderProps) {
	const { children, ...rest } = props;

	const value = {
		...defaultValue,
		...rest,
	};

	return <SiteContext.Provider value={value}>{children}</SiteContext.Provider>;
}

export interface SiteContextProps extends Site {
	[key: string]: unknown;
	linkComponent: (props: GriddoLinkProps) => JSX.Element;
	navigate: () => null;
	selectEditorID?(
		selectedComponent: {
			editorID?: number;
			component: string;
			type?: string;
			parentEditorID?: number;
		},
		parentComponent: string | undefined | null,
		e: React.SyntheticEvent
	): void;
	selectHoverEditorID?(editorID: number): void;
	moduleActions?: {
		deleteModuleAction: (editorID: number) => void;
		duplicateModuleAction: (editorID: number) => void;
	};
}

export interface SiteProviderProps extends Site {
	children: JSX.Element;
}

export { SiteContext, SiteProvider };
